// componentes Material.
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import { dashboarStyless } from './makeStyles/dashboardStyless';
// componentes.
import NavItem from './NavItem';
// iconos.
import IconButton from '@material-ui/core/IconButton';
import { Users, Grid } from 'react-feather';

type Props = {
  open: boolean;
  onClose: Function;
};

const items = [
  {
    href: '/pagos-cati',
    icon: Grid,
    title: 'Pagos CATI'
  },
  {
    href: '/usuarios',
    icon: Users,
    title: 'Usuarios'
  },
];

const DashboardSidebar: React.FC<Props> = ({ onClose, open }) => {
  const { headerSidebar, boxSidebar } = dashboarStyless();

  const content = (
    <Box className={boxSidebar}>
      <Box
        className={headerSidebar}
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          p: 1,
          pl: 2,
        }}
      >
        <IconButton
          onClick={() => onClose(false)}
          style={{ color: 'white' }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              showMenu={onClose}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      onClose={() => onClose(false)}
      open={open}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
