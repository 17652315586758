import { useRef, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
    getAllListItems,
    getInfoToDownload,
} from "../../services/pagosCati";

const animatedComponents = makeAnimated();
const ListItem = () => {
    const isMounted = useRef(false);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const onDownloadInfo = async () => {
        setLoading(true);
        const data = await getInfoToDownload(selectedItems);
        if (data.ok && isMounted.current) {
            // descargar el contenido.
            const link = document.createElement("a");
            link.href = data.url;
            link.target = "_blank";
            link.setAttribute("download", data.fileName);
            document.body.appendChild(link);
            link.click();

            // limpiar lista
            setSelectedItems([]);
        }
        setLoading(false);
    };

    useEffect(() => {
        isMounted.current = true;
        setIsLoading(true);

        getAllListItems().then((resp) => {
            if (resp.ok && isMounted.current) {
                const itemList = resp.rows.map((item: any) => ({
                    value: item.estudio,
                    label: `Estudio ${item.estudio}`,
                }));
                setItems(itemList);
                setIsLoading(false);
            }
        });

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <Card style={{ minHeight: '80vh' }}>
            {loading && <LinearProgress />}
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Select
                            isMulti
                            isDisabled={loading}
                            placeholder="Seleccione."
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            onChange={(val: any) => setSelectedItems(val)}
                            isLoading={isLoading}
                            options={items}
                        />
                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                            onClick={onDownloadInfo}
                        >
                            Descargar.
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ListItem;
