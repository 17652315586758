import Grid from '@material-ui/core/Grid';
import ListItem from '../../List/ListItem';
import DownloadByDate from '../../Downloads/DownloadByDate';
import Tab from '../../Tab/Tab';
import { useEffect } from 'react';
import { useGlobalContext } from '../../../context/AppContext';
import { useRef } from 'react';


const PagosCati: React.FC<{}> = () => {
  const { setTitle } = useGlobalContext();
  const isMounted = useRef(false);

  const tabs = [
    {title:'Descarga por estudio', children: <ListItem/>},
    {title:'Descarga por fecha', children: <DownloadByDate/>},
  ]

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) setTitle('Pagos CATI');
    return () => {isMounted.current = false}
  }, [setTitle])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Tab tabs={tabs}/>
      </Grid>
    </Grid>
  )
}

export default PagosCati
