import { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useGlobalContext } from '../context/AppContext';
import { checkLocalToken } from '../services/loginService';

type privateRouteProps = {
  isAuth: boolean;
  cp: any;
  path: string;
  exact: boolean;
}

const PrivateRoute: React.FC<privateRouteProps> = ({ isAuth, cp: Component, ...rest }) => {
  const { setGlobalLoading } = useGlobalContext();
  const { pathname } = useLocation();

  useEffect(() => {
    setGlobalLoading(true);
    checkLocalToken()
      .finally(() => setGlobalLoading(false));
  }, [ pathname, setGlobalLoading ])

  return (
    <Route {...rest}
      component={(props: any) => (
        ( isAuth ) ? <Component { ...props } /> : ( <Redirect to="/info" /> )
      )}
    />
  )
}

export default PrivateRoute;