import axios from "axios"
import { API_BASE_URL } from "../config/config";
const URL = `${API_BASE_URL}/pagos-cati`

export const getAllListItems = async () => {
  try {
    const response = await axios({
      url: `${URL}/get-list-table`,
      method: 'GET',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const getInfoToDownload = async (params: any) => {
  try {
    const response = await axios({
      url: `${URL}/info-to-download`,
      method: 'POST',
      data: {estudiosList: params}
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const DownloadDataByDate = async (fecha_ini: string, fecha_fin: string) => {
  try {
    const response = await axios({
      url: `${URL}/download-by-date`,
      method: 'POST',
      data: {fecha_ini: fecha_ini, fecha_fin:fecha_fin}
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}