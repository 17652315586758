// import React from "react";
import { useState } from "react";
import Card from "@material-ui/core/Card";
import { Button, CardContent, Grid, LinearProgress, TextField } from "@material-ui/core";
import { DownloadDataByDate } from "../../services/pagosCati";
import moment from "moment";


const DownloadByDate = () => {

    const today = moment().format('YYYY-MM-DD');
    const today_plus_30 = moment().add(15, 'days').format('YYYY-MM-DD');
    //  

    const [loading, setLoading] = useState(false);
    const [fechaDesde, setFechaDesde] = useState(today);
    const [fechaHasta, setFechaHasta] = useState(today_plus_30);


    const handleFechaDesdeChange = (e: any) => {
        setFechaDesde(e.target.value);
    }

    const handleFechaHastaChange = (e: any) => {
        setFechaHasta(e.target.value);
    }
    
    

    const onDownloadInfo = async () => {
        setLoading(true);
        const data = await DownloadDataByDate(fechaDesde, fechaHasta);
        console.log(fechaDesde, fechaHasta);
        if (data.ok) {
            // descargar el contenido.
            const link = document.createElement("a");
            link.href = data.url;
            link.target = "_blank";
            link.setAttribute("download", data.fileName);
            document.body.appendChild(link);
            link.click();
        }
        setLoading(false);
    };

    return <>
        <Card style={{ minHeight: '80vh' }}>
            {loading && <LinearProgress />}
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xl={6} xs={6}>
                        <form noValidate>
                            <TextField
                                id="date"
                                label="Fecha desde"
                                type="date"
                                defaultValue={fechaDesde}
                                onChange={handleFechaDesdeChange}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                        </form>
                    </Grid>
                    <Grid item lg={6} sm={6} xl={6} xs={6}>
                        <form noValidate>
                            <TextField
                                id="date"
                                label="Fecha hasta"
                                type="date"
                                defaultValue={fechaHasta}
                                onChange={handleFechaHastaChange}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                        </form>
                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                            onClick={onDownloadInfo}
                        >
                            Descargar.
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </>
}

export default DownloadByDate;