import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { completeSsoRedirect } from '../config/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkLocalToken } from '../services/loginService';
import './styless.css';

const LoginRequireScreen = () => {
  const [ cookies ] = useCookies(['token', 'rol']);
  const { token, rol } = cookies;

  const test = async () => {
    localStorage.setItem('rol', rol);
    const data = await checkLocalToken();

    if(data.ok){
      window.location.href = "/";
    }

  }

  useEffect(() => {
    if(token){
      test();
    }else{
      localStorage.clear();
      window.location.href = completeSsoRedirect;
    }
  }, [ token ])

  return (
    <div className="unauthorized-content">
      <CircularProgress />
    </div>
  )
}

export default LoginRequireScreen
