import PagosCati from '../Components/views/PagosCati/PagosCati';
import Info from '../Components/Pages/Info/Info';
import UsersMainScree from '../Components/views/Users/UsersMainScree';

export const rutesList = [
  {
    path: '/info',
    cp: Info,
    exact: true,
    roles: ['rol-admin', 'rol-lector', 'rol-default']
  },
  {
    path: '/pagos-cati',
    cp: PagosCati,
    exact: true,
    roles: ['rol-admin', 'rol-lector']
  },
  {
    path: '/usuarios',
    cp: UsersMainScree,
    exact: true,
    roles: ['rol-admin']
  }
]