import { useState } from 'react'
import { Container, Grid } from '@material-ui/core';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { dashboarScreenStyless } from './makeStyles/dashboardStyless';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useGlobalContext } from '../../../context/AppContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

type Props = {
  children: React.ReactChild
}

const DashboardScreen: React.FC<Props> = ({ children }) => {
  const { globalLoading } = useGlobalContext();
  const classes = useStyles();
  const { content, appBarSpacer, container } = dashboarScreenStyless();
  const [isNavOpen, setNavOpen] = useState(false);

  return (
    <div>
      <DashboardNavbar setNavOpen={setNavOpen}/>
      <DashboardSidebar
        onClose={setNavOpen}
        open={isNavOpen}
      />
      <main className={content}>
        <div className={appBarSpacer} />
        <Container maxWidth="xl" className={container}>
          <Backdrop className={classes.backdrop} open={globalLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container spacing={3}>
            { children }
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default DashboardScreen
